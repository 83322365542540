const formatPassportString = (event) => {
	const value = event.target.value.toUpperCase();
	const passportRegex = /^[A-Za-z0-9]+$/;

	let sanitizedValue = "";

	for (const char of value) {
		if ((sanitizedValue + char).length > 12) break;
		if (passportRegex.test(sanitizedValue + char)) sanitizedValue += char;
	}

	event.target.value = sanitizedValue;

	return event;
};

export default formatPassportString;
