import React, { memo, useMemo } from "react";
import { FormHelperText } from "@mui/material";
import PropTypes from "prop-types";

import AppIcon from "components/app-icon";
import checkerIcon from "assets/images/components/app-checkbox/checker-icon.svg";
import checkerDisabledIcon from "assets/images/components/app-checkbox/checker-disabled-icon.svg";

const AppCheckbox = (props) => {
	const activated = useMemo(() => props.value, [props.value]);
	const isErrorField = useMemo(() => !!props.error && !!props.touched, [props.error, props.touched]);
	const errorMessage = useMemo(() => (isErrorField ? props.error : ""), [props.error, isErrorField]);
	const Logo = useMemo(() => props.logo, [props.logo]);

	return (
		<div className="app-checkbox">
			<button className="checkbox" type="button" disabled={props.disabled} name={props.name} checked={activated} onClick={props.onClick}>
				<div className="checkbox__box">{(props.disabled && activated && <AppIcon src={checkerDisabledIcon} />) || (!props.disabled && activated && <AppIcon src={checkerIcon} />)}</div>

				{Logo && <img className="checkbox__logo" src={Logo} alt="logo" />}

				<p className="checkbox__label">{props.label}</p>
			</button>
			<FormHelperText className="checkbox__error">{errorMessage}</FormHelperText>
		</div>
	);
};

AppCheckbox.propTypes = {
	value: PropTypes.bool,
	checked: PropTypes.bool,
	logo: PropTypes.string,
	disabled: PropTypes.bool,
	className: PropTypes.string,
	name: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

export default memo(AppCheckbox);
