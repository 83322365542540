import React, { forwardRef, Fragment, useRef, useEffect, useState } from "react";

import timerCounter from "common/timer-counter";
import AppExpiredModal from "components/app-expired-modal";

const withAppTimeout = (WrappedComponent) => {
	return forwardRef(({ ...props }, ref) => {
		const second = 900;
		const expiredModalRef = useRef();
		const [count, setCount] = useState(second);

		const secondsToTime = (e) => {
			/* prettier-ignore */
			const minutes = Math.floor((e % 3600) / 60).toString().padStart(2, "0");
			/* prettier-ignore */
			const secs = Math.floor(e % 60).toString().padStart(2, "0");

			return minutes + ":" + secs;
		};

		useEffect(() => {
			const timerWorker = new Worker(timerCounter);

			timerWorker.postMessage({ turn: "on" });

			timerWorker.onmessage = ({ data: { time } }) => {
				if (time <= 0) {
					expiredModalRef.current.onHandleShow();
					setCount(0);
					timerWorker.postMessage({ turn: "off" });
				} else {
					setCount(time);
				}
			};

			return () => timerWorker.postMessage({ turn: "off" });
		}, []);

		return (
			<Fragment>
				<AppExpiredModal ref={expiredModalRef} />

				<WrappedComponent {...props} ref={ref} timer={secondsToTime(count)} />
			</Fragment>
		);
	}, []);
};
export default withAppTimeout;
