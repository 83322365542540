const encodeQueryParams = (data) => {
	if (!data) return "";

	if (typeof data !== "object") return data;

	const query = [];
	for (let q in data) query.push(encodeURIComponent(q) + "=" + encodeURIComponent(data[q]));

	return "?" + query.join("&");
};

export default encodeQueryParams;
