import React from "react";

import logo from "assets/images/pages/page-app-store/app-download.webp";
import appleAppStoreIcon from "assets/images/pages/page-app-store/apple-app-store-icon.svg";
import googleAppStoreIcon from "assets/images/pages/page-app-store/google-app-store-icon.svg";
import huaweiAppStoreIcon from "assets/images/pages/page-app-store/huawei-app-store-icon.svg";

const PageAppStore = () => {
	const onHandleNavigateAppIos = () => {
		window.open(process.env.REACT_APP_APP_STORE);
	};

	const onHandleNavigateAppAndroid = () => {
		window.open(process.env.REACT_APP_PLAY_STORE);
	};

	const onHandleNavigateAppHuawei = () => {
		window.open(process.env.REACT_APP_GALLERY);
	};

	return (
		<div className="page-app-store">
			<div className="app-store">
				<main className="main">
					<div className="main__content main__content--background">
						<div className="main__logo">
							<img src={logo} alt="logo" />
						</div>
					</div>

					<div className="main__content main__content--form">
						<div className="main__form">
							<h1 className="main__title">
								MAKE LIFE EASY <br></br> with easycover.my
							</h1>
							<p className="main__description">Experience the Ultimate Insurance Superapp. Download Now!</p>

							<div className="main__button-container">
								<button type="button" className="main__button" onClick={onHandleNavigateAppIos}>
									<img src={appleAppStoreIcon} alt="apple" />
								</button>

								<button type="button" className="main__button" onClick={onHandleNavigateAppAndroid}>
									<img src={googleAppStoreIcon} alt="google" />
								</button>

								<button type="button" className="main__button" onClick={onHandleNavigateAppHuawei}>
									<img src={huaweiAppStoreIcon} alt="huawei" />
								</button>
							</div>
						</div>
					</div>
				</main>
			</div>
		</div>
	);
};

export default PageAppStore;
