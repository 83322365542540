import React, { forwardRef, useEffect, useCallback, useState } from "react";

import COMMON from "common";
import sanitizeError from "common/sanitize-error";

const withAppIntegration = (WrappedComponent) => {
	return forwardRef(({ ...props }, ref) => {
		const [initialize, setinitialize] = useState(false);

		//prettier-ignore
		const onHandleRequestError = useCallback((error) => {
			const rnWebViewPostMessage = window?.directPostMessage;
	
			if(rnWebViewPostMessage) {
				rnWebViewPostMessage(JSON.stringify(error));
				rnWebViewPostMessage(JSON.stringify({ action: COMMON.EVENTS.FAILED, message: sanitizeError(error)}));
			}
		}, []);

		useEffect(() => {
			const isUIWebView = () => navigator.userAgent.toLowerCase().match(/\(ip.*applewebkit(?!.*(version|crios))/);
			const rnWebViewPostMessage = window?.directPostMessage;
			const receiver = isUIWebView() ? window : document;
			let counterLoaded = null;
			let initializeCounterLoaded = null;

			const onHandlePostMessage = (message) => {
				let data = null;
				try {
					data = JSON.parse(message.data);

					if (data?.isMobile) {
						document.querySelector("body").classList.add("ezc-app");
						sessionStorage.setItem(COMMON.EVENTS.APP, COMMON.EVENTS.APP);
					}

					if (data.token) {
						sessionStorage.setItem(COMMON.AUTH_TOKEN, data.token);
					}
				} catch (error) {
					onHandleRequestError(error);
				}
			};

			receiver.addEventListener("message", onHandlePostMessage);

			counterLoaded = setTimeout(() => {
				if (rnWebViewPostMessage) rnWebViewPostMessage(JSON.stringify({ action: COMMON.EVENTS.LOADED }));
			}, 100);

			initializeCounterLoaded = setTimeout(() => {
				setinitialize(true);
			}, 500);

			return () => {
				clearTimeout(counterLoaded);
				clearTimeout(initializeCounterLoaded);
				receiver.removeEventListener("message", onHandlePostMessage);
			};
		}, [onHandleRequestError]);

		if (!initialize) return null;

		return <WrappedComponent {...props} ref={ref} />;
	});
};
export default withAppIntegration;
