import React, { useMemo, useCallback, useRef, useState, Fragment } from "react";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik, FieldArray, FormikProvider, setNestedObjectValues } from "formik";

import COMMON from "common";
import ERRORS from "common/errors";
import pathnames from "routes/pathnames";
import classNames from "common/class-names";
import { CALENDAR_FORMAT } from "common/calendar";
import serveRequestErrors from "common/serve-request-errors";
import { getDateOfBirthAge } from "common/get-date-of-birth-age";
import formatPassportString from "common/format-passport-string";
import { getDateOfBirthNRIC, validateNRIC } from "common/nric-helpers";
import formatCurrencyPattern, { formatCurrency } from "common/format-currency-pattern";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppCardInfo from "components/app-card-info";
import AppButtonInput from "components/app-button-input";
import AppSearchInput from "components/app-search-input";
import AppSelectInput from "components/app-select-input";
import AppMobileInput from "components/app-mobile-input";
import AppCloseIcon from "components/icons/app-close-icon";
import AppMaskingInput from "components/app-masking-input";
import AppCalendarInput from "components/app-calendar-input";
import AppCard from "components/pages/page-quotation/app-card";
import AppChevronIcon from "components/icons/app-chevron-icon";
import AppMultiSelectInput from "components/app-multi-select-input";
import AppCardContainer from "components/pages/page-quotation/app-card-container";
import AppRegenerateModal from "components/pages/page-quotation/app-regenerate-modal";
import AppAccordionContainer from "components/pages/page-quotation/app-accordion-container";
import api from "services/api";
import getOccupationListing from "services/get-occupation-listing";
import getRelationshipListing from "services/get-relationship-listing";
import getMaritalStatusListing from "services/get-maritalStatus-listing";
import getIdentificationListing from "services/get-identification-listing";
import { setQuotation } from "store/slices/motor";
import addIcon from "assets/images/add-green-icon.svg";
import refreshIcon from "assets/images/refresh-icon.svg";
import editIcon from "assets/images/pages/page-quotation/edit-icon.svg";
import clockIcon from "assets/images/pages/page-quotation/clock-icon.webp";
import emptyIcon from "assets/images/pages/page-quotation/empty-box-icon.webp";

const calendarDisplayFormat = CALENDAR_FORMAT.DATE_FORMAT + " " + CALENDAR_FORMAT.MONTH_FORMAT + " " + CALENDAR_FORMAT.YEAR_FORMAT;

const PageQuotation = () => {
	const searchInputRef = useRef();
	const timerCounter = useRef();
	const regenerateModalRef = useRef();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [loading, setLoading] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [additionalFieldsVisible, setAdditionalFieldsVisible] = useState(false);
	const motor = useSelector((state) => state.motor);
	const enquiryInfo = useMemo(() => motor?.enquiry, [motor?.enquiry]);
	const schema = useMemo(() => yup.string().required(ERRORS.REQUIRED), []);
	const quotationInfo = useMemo(() => motor?.quotation, [motor?.quotation]);
	const vehicleInformation = useMemo(() => quotationInfo?.vehicle, [quotationInfo?.vehicle]);
	const nvicDropdown = useMemo(() => quotationInfo?.nvicDropdown, [quotationInfo?.nvicDropdown]);
	const selectedNVIC = useMemo(() => quotationInfo?.selectedNVIC, [quotationInfo?.selectedNVIC]);

	//prettier-ignore
	const quotationCheckout = useMemo(() => quotationInfo?.quotationCheckout?.filter((o) => { return  o.status !== COMMON.MESSAGE_TYPE.FAILED }), [quotationInfo?.quotationCheckout]);

	const additionalDriverValidateScheme = useMemo(() => {
		const schemes = {
			name: schema,
			dob: schema,
			identificationType: schema,
			passport: yup.string().when(["identificationType"], {
				is: (identificationType) => identificationType === COMMON.IDENTIFICATION_TYPE.PASSPORT,
				then: () => yup.string().required(ERRORS.REQUIRED),
			}),
			nric: yup.string().when(["identificationType"], {
				is: (identificationType) => identificationType === COMMON.IDENTIFICATION_TYPE.NRIC,
				then: () =>
					yup
						.string()
						.required(ERRORS.REQUIRED)
						.test("validate nric", ERRORS.NRIC, validateNRIC)
						.test("validate duplicate", "This NRIC has been used on policy owner.", function (value) {
							const current = value;
							return current !== enquiryInfo?.identificationNo;
						})
						.test("validate duplicate", "This NRIC has been used on other additional driver.", function (value) {
							const driverArr = this?.from?.[1]?.value?.additionalDriver.map((item) => {
								return item.nric;
							});

							//prettier-ignore
							const duplicatedItemList = driverArr?.map((o) => {
								if (o === value) return o;
								
								return undefined
							}).filter((o) => o);

							return !(duplicatedItemList.length >= 2);
						}),
			}),
			email: yup.string().matches(COMMON.REGEX.EMAIL, ERRORS.EMAIL).required(ERRORS.REQUIRED),
			mobile: schema,
			gender: schema,
			relationship: schema,
			maritalStatus: schema,
			occupation: schema,
			experience: schema,
		};
		return schemes;
	}, [schema, enquiryInfo?.identificationNo]);

	//prettier-ignore
	const additionalDriverFields = useMemo(() => ({ name: "", dob: "", identificationType: "", passport: "", nric: "", email: "", mobile: "", prefixNo: "", gender: "", relationship: "", maritalStatus: "", occupation: "", experience: "" }), []);

	//prettier-ignore
	const initialValues = useMemo(() => {

		const defaultNVIC = nvicDropdown?.find((o) => o.nvic === selectedNVIC);

		let payload = {
			sumInsured: "",
			nvicCode: quotationInfo?.selectedNvicCode || defaultNVIC?.nvic,
			nvicCodeDisplay: quotationInfo?.selectedNvicCode || defaultNVIC?.nvic,
			sort: "",
			insurerList: [],
			insurer: quotationInfo?.provider || quotationCheckout?.[0]?.insurer,
			isAllBenefit: false,
			benefits: {},
			additionalDriver: [additionalDriverFields],
			marketCoverage: true,
			agreedCoverage: false,
			ismMarketValue: "",
			totalPayable: "",
			vehicleBody: "SALOON",
			vehicleBodyDisplay: "SALOON" ,
			minimumMarketValue: 0,
			maximumMarketValue: 0,
			minimumAgreedValue: 0,
			maximumAgreedValue: 0,
			allianzVariant: quotationInfo?.selectedAllianzNVIC || "",
			allianzVariantDisplay: "",
			allianzCoverage: quotationInfo?.allianzSumInsured || 0,
			reconCar: "No",
		}

		if (quotationInfo) {
			const selectedQuotation = quotationCheckout?.find((o) => o.insurer === payload?.insurer);

			const quotationNvic = selectedQuotation?.nvic?.find((o) => o.nvic === payload?.nvicCode);

			const selectedNvic = nvicDropdown?.find((o) => o.nvic === payload?.nvicCode);

			const selectedAllianzNvic = nvicDropdown?.find((o) => o.nvic === payload?.allianzVariant);

			if (selectedNvic?.minMarketValue) payload.minimumMarketValue = selectedNvic?.minMarketValue;

			if (selectedNvic?.maxMarketValue) payload.maximumMarketValue = selectedNvic?.maxMarketValue;

			if (selectedNvic?.minAgreedValue) payload.minimumAgreedValue = selectedNvic?.minAgreedValue;

			if (selectedNvic?.maxAgreedValue) payload.maximumAgreedValue = selectedNvic?.maxAgreedValue;

			if (selectedAllianzNvic?.nvic) payload.allianzVariantDisplay = selectedAllianzNvic?.modelDescription;
			
			if (selectedQuotation?.totalPayable) payload.totalPayable = selectedQuotation?.totalPayable;

			if (selectedQuotation?.insurer) payload.insurer = selectedQuotation?.insurer;

			if (selectedQuotation) payload.selectedQuotation = selectedQuotation;

			if (selectedQuotation) {
				selectedQuotation?.extraBenefit?.forEach((o) => {

					const benefitKey = `benefit_${o?.benefitCode}`;
	
					const windscreenProtection = o?.benefitCode === COMMON.EXTRA_BENEFITS.WINDSCREEN_PROTECTION;
	
					payload.benefits[benefitKey] = o.benefitIncluded;
					
	
					if (o?.benefitOption) {
						payload.benefits[benefitKey + "_value"] = o?.benefitOption?.[0]?.benefitCode;
					}
	
					if (windscreenProtection) {
						payload.benefits[benefitKey + "_value"] = formatCurrency(500);
					}
				});
			}

			if (selectedQuotation?.selectedExtraBenefit?.length) {

				selectedQuotation?.selectedExtraBenefit.forEach((o)=> {
					const benefitKey = `benefit_${o?.benefitCode}`;

					switch(o.benefitCode) {
						case COMMON.EXTRA_BENEFITS.ALL_DRIVER:
								payload.benefits["benefit_AD"] = true;
								payload.benefits["benefit_AD_value"] = COMMON.EXTRA_BENEFITS.ALL_DRIVER;
							break;
						case COMMON.EXTRA_BENEFITS.ADDITIONAL_DRIVERS:
								payload.benefits["benefit_AD"] = true;
								payload.benefits["benefit_AD_value"] = COMMON.EXTRA_BENEFITS.ADDITIONAL_DRIVERS
							break;
						case COMMON.EXTRA_BENEFITS.WINDSCREEN_PROTECTION:
								payload.benefits[benefitKey] = true;
								payload.benefits[benefitKey + "_value"] = o?.benefitSumInsured;
							break;
						default:
								payload.benefits[benefitKey] = true;
							break;
					}
				});

			}

			if (selectedQuotation?.sumInsured) {
				const sumInsuredValue = selectedQuotation.insurer === COMMON.PROVIDERS.ALLIANZ ? formatCurrency(selectedNvic.maxMarketValue) : formatCurrency(selectedQuotation.sumInsured);

				payload.sumInsured = sumInsuredValue;
				payload.sumInsuredDisplay = sumInsuredValue;
			}

			if (quotationNvic) payload.quotationNvic = quotationNvic;

			if (quotationNvic?.ismMarketValue) {
				payload.ismMarketValue = formatCurrency(quotationNvic?.ismMarketValue);
			}

			if (quotationNvic?.nvic) {
				payload.nvicCode = quotationNvic?.nvic;
				payload.nvicCodeDisplay = quotationNvic?.nvic;
			}

			if (quotationInfo.isAllBenefit) payload.isAllBenefit = quotationInfo.isAllBenefit;

			if (selectedQuotation?.additionalDriverList?.length) {

				payload.additionalDriver = selectedQuotation?.additionalDriverList?.map((o) => ({ 
					name: o.name,
					dob: o.dateOfBirth,
					identificationType: o.identificationType,
					nric: o.identificationType === COMMON.IDENTIFICATION_TYPE.NRIC ? o.identificationNumber : "",
					passport: o.identificationType === COMMON.IDENTIFICATION_TYPE.PASSPORT ? o.identificationNumber: "",
					email: o.email,
					mobile: o.mobile,
					prefixNo: o.prefixNo,
					gender: o.gender,
					occupation: o.occupation,
					relationship: o.relation,
					experience: o.experience,
					maritalStatus: o.maritalStatus
				}));

			}

			if (quotationInfo.agreedValue) {
				payload.agreedCoverage = quotationInfo.agreedValue;
				payload.marketCoverage = false;
			}

			if (quotationInfo.vehicleBody) {
				payload.vehicleBody = quotationInfo?.vehicleBody;
				payload.vehicleBodyDisplay = quotationInfo?.vehicleBody;
			}
		}

		return payload;
	}, [nvicDropdown, quotationInfo, quotationCheckout, additionalDriverFields, selectedNVIC]);

	//prettier-ignore
	const formik = useFormik({
		validateOnBlur: true,
		initialValues,
		validationSchema: yup.object({
			sumInsured: yup.string().required().test("validate sum insured range", "Enter the amount within the market value range.", function (value) {
				const current = value;

				if (this.parent.agreedCoverage) return (parseFloat(current?.replace(",", "")) >= this.parent.minimumAgreedValue && parseFloat(current?.replace(",", "")) <= this.parent.maximumAgreedValue); 
				else return (parseFloat(current?.replace(",", "")) >= this.parent.minimumMarketValue && parseFloat(current?.replace(",", "")) <= this.parent.maximumMarketValue);
			}),
			isAllBenefit: yup.boolean(),
			insurer: yup.string().test("validate insurer", "The field is required", function (value) {
				if (quotationCheckout.length) {
					return yup.string().required(ERRORS.REQUIRED);
				}
				else {
					return yup.string()
				}
			 }),
			additionalDriver: yup.array().when(["benefits"], {
				is: (benefits) => {
					return benefits["benefit_AD"] && benefits["benefit_AD_value"] === COMMON.EXTRA_BENEFITS.ADDITIONAL_DRIVERS
				},
				then: () => yup.array().of(yup.object(additionalDriverValidateScheme))
			})
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		},
	});

	const libertyListOptions = useMemo(() => quotationCheckout?.map((o) => ({ label: o.insurer, value: o.insurer, ...o })), [quotationCheckout]);

	const [filter, setFilter] = useState({ insurerList: [], sort: "" });
	const selectedQuotationExtraBenefit = useMemo(() => quotationCheckout?.filter((o) => o.insurer === formik.values.insurer)?.[0]?.extraBenefit, [quotationCheckout, formik.values.insurer]);
	const selectedQuotationTotalPayable = useMemo(() => quotationCheckout?.filter((o) => o.insurer === formik.values.insurer)?.[0]?.totalPayable, [quotationCheckout, formik.values.insurer]);
	const totalQuotationDescription = useMemo(() => {
		let total = "-";

		if (quotationCheckout && !isLoading) total = quotationCheckout?.length;
		else if (isLoading) total = "-";
		else total = "0";

		return `Total ${total}`;
	}, [quotationCheckout, isLoading]);

	const onHandleNavigateEnquiry = () => {
		navigate(pathnames.enquiry);
	};

	const onHandleNavigateApplicant = () => {
		navigate(pathnames.applicant);
	};

	const onHandleOpenRegenerate = () => {
		if (isLoading) return;

		regenerateModalRef.current.onHandleShow(formik.values);
	};

	const onHandleAdditionalFieldsVisible = useCallback(() => {
		setAdditionalFieldsVisible((prev) => !prev);
	}, []);

	//prettier-ignore
	const VehicleData = useMemo(() => ({
		title: "Vehicle Details",
		info: [
			{ label: "Registration No.", value: formik?.values?.selectedQuotation?.registrationNumber ?? "-" },
			{ label: "Model", value: formik?.values?.quotationNvic?.modelDescription ?? "-" },
			{ label: "Make Year", value: formik?.values?.quotationNvic?.makeYear ?? "-" },
			{ label: "Engine Displacement (CC)", value: formik?.values?.quotationNvic?.capacity ?? "-" },
			{ label: "Chassis No.", value: formik?.values?.selectedQuotation?.chassisNumber ?? "-" },
			{ label: "Engine No.", value: formik?.values?.selectedQuotation?.engineNumber ?? "-" },
			{ label: "Seat", value: formik?.values?.quotationNvic?.seat ?? "-" },
			{ label: "Transmission", value: formik?.values?.quotationNvic?.transmissionType ?? "-" },
			{ label: "Build Type", value: formik?.values?.quotationNvic?.buildType ?? "-" },
			{ label: "No Claim Discount (NCD) Percentage", value: `${formik?.values?.selectedQuotation?.noClaimDiscount ?? 0}%` },
			{ label: "ISM Market Value", value: formik?.values?.quotationNvic?.ismMarketValue ? `RM${formatCurrency(formik?.values?.quotationNvic?.ismMarketValue  ?? 0)}` : "-" },
		]
	}), [formik?.values?.selectedQuotation, formik?.values?.quotationNvic]);

	const onHandleStartLoading = () => {
		clearInterval(timerCounter.current);

		timerCounter.current = setInterval(() => {
			setIsLoading(true);
			setLoading((prev) => {
				if (prev.length === 3) return "";
				return (prev += "•");
			});
		}, 1000);
	};

	//prettier-ignore
	const onHandleChecked = useCallback((value) => {
		const selectedQuotation = quotationCheckout?.find((o) => o.insurer === value);
		const quotationNvic = selectedQuotation?.nvic?.find((o) => o.nvic === formik.values.nvicCode);

		let payload = {
			benefits : {}
		}

		if (selectedQuotation && value !== formik.values.insurer) {
			const maximumValue = formik.values.marketCoverage ? formik.values.maximumMarketValue : formik.values.maximumAgreedValue;
			const sumInsuredValue = selectedQuotation.insurer === COMMON.PROVIDERS.ALLIANZ ? formatCurrency(maximumValue) : formatCurrency(selectedQuotation.sumInsured);

			selectedQuotation?.extraBenefit?.forEach((o) => {

				const benefitKey = `benefit_${o?.benefitCode}`;

				formik.resetForm({ values: { ...formik.initialValues, benefits: payload.benefits } });

				const windscreenProtection = o?.benefitCode === COMMON.EXTRA_BENEFITS.WINDSCREEN_PROTECTION;

				formik.setFieldValue(`benefits[${benefitKey}]`, o.benefitIncluded);

				payload.benefits[benefitKey] = o.benefitIncluded;

				if (o?.benefitOption) {
					formik.setFieldValue(`benefits[${benefitKey}_value]`, o?.benefitOption?.[0]?.benefitCode);
					payload.benefits[benefitKey + "_value"] = o?.benefitOption?.[0]?.benefitCode;
			
				}

				if (windscreenProtection) {
					formik.setFieldValue(`benefits[${benefitKey}_value]`, formatCurrency(500));
					payload.benefits[benefitKey + "_value"] = formatCurrency(500);
				}
			});

			formik.resetForm({ values: { ...formik.initialValues, benefits: payload.benefits, insurer: value, sumInsured:sumInsuredValue, sumInsuredDisplay: sumInsuredValue, additionalDriver:[additionalDriverFields], selectedQuotation: selectedQuotation, quotationNvic: quotationNvic, isAllBenefit: false } });

		}
		
	}, [formik, quotationCheckout, additionalDriverFields]);

	//prettier-ignore
	const onHandleSearch = useCallback(() => {
		setFilter({ insurerList: formik.values.insurerList, sort: formik.values.sort })
		searchInputRef.current.onhandleCloseAdvanceSearch();
	}, [formik]);

	const onHandleResetSearch = () => {
		formik.setValues(initialValues);
		setFilter({ insurerList: [], sort: "" });
		searchInputRef.current.onhandleCloseAdvanceSearch();
	};

	const onHandleCloseSearch = () => {
		searchInputRef.current.onhandleCloseAdvanceSearch();
	};

	const onHandleRemoveField = (field, fieldValue) => {
		switch (field) {
			case "sort":
				formik.setFieldValue(field, "");
				setFilter({ insurerList: formik.values.insurerList, sort: formik.initialValues.sort });
				break;
			case "insurerList":
				const nextInsurerList = formik.values.insurerList.filter((o) => o !== fieldValue);
				setFilter({ insurerList: nextInsurerList, sort: formik.values.sort });
				formik.setFieldValue(field, nextInsurerList);
				break;
			default:
				break;
		}
	};

	//prettier-ignore
	const onHandleInsurerList = useCallback((values) => {
		formik.setFieldValue("insurerList", values);
	}, [formik]);

	//prettier-ignore
	const onHandleCheckbox = useCallback((event) => {
		const name = event.currentTarget.name;
		const current = event.currentTarget.checked;

		if (current) formik.setFieldValue("isAllBenefit", false);
		
		if (!current) {
			const benefits = { ...formik.values.benefits, [name]: true };

			const isAllBenefits = Object.keys(benefits).every(o => benefits[o]);

			formik.setFieldValue("isAllBenefit", isAllBenefits);
		}
		
		if (name === `benefit_${COMMON.EXTRA_BENEFITS.DRIVERS_COVERAGE}` && current) {
			const driverCoverageValue = `benefit_${COMMON.EXTRA_BENEFITS.DRIVERS_COVERAGE}_value`;

			formik.setFieldValue(`benefits[${driverCoverageValue}]`,COMMON.EXTRA_BENEFITS.ALL_DRIVER );
		}
		
		formik.setFieldValue(`benefits[${name}]`, !current);
	}, [formik]);

	//prettier-ignore
	const onHandleValue = useCallback((event) => {
		const name = event.target.name;
		const value = event.target.value;

		formik.setFieldValue(`benefits[${name}]`, value);
	}, [formik]);

	const onHandleSwitch = useCallback(() => {
		const isAllBenefit = formik.values.isAllBenefit;
		const benefits = { ...formik.values.benefits };

		//prettier-ignore
		const benefitsNeedToCheck = selectedQuotationExtraBenefit?.map((o) => {
			if (o.benefitIncluded) {
				return o.benefitCode;
			}
			return null;
		}).filter((o) => o);

		Object.keys(benefits).forEach((a) => {
			const benefitCode = a.replace("benefit_", "");

			if (!benefitsNeedToCheck.includes(benefitCode) && !benefitCode.includes("_value")) {
				benefits[a] = !isAllBenefit;
			}
		});

		formik.setValues({ ...formik.values, benefits, isAllBenefit: !isAllBenefit });
	}, [formik, selectedQuotationExtraBenefit]);

	/* prettier-ignore */
	const IdentificationField = useCallback((obj) => {
		const onHandleDob = (event) => {
			const name = event.target.name;
			const value = event.target.value;

			if (!value) {
				obj.formik.setFieldValue(`additionalDriver.${obj.index}.dob`, "");
			}
			
			obj.formik.setFieldValue(name, value);
			obj.formik.setFieldTouched(name)
	
			const dob = getDateOfBirthNRIC(value);

			if (!dob) {
				obj.formik.setFieldValue(`additionalDriver.${obj.index}.dob`, "");
				return;
			}

			obj.formik.setFieldValue(`additionalDriver.${obj.index}.dob`, new Date(dob).toISOString());
		}

		if (!obj.isPassport && obj.validIdentificationType) {
			/* prettier-ignore */
			return <AppMaskingInput required type="text" name={`${obj.name}.nric`} label="NRIC No." placeholder="e.g. 901010-14-1234" disabled={obj.disabled?.nric} value={obj.values?.nric} error={obj.errors?.nric} touched={obj.touched?.nric} onChange={onHandleDob} format={COMMON.MASKING.NRIC} /> 
		}

		if (obj.isPassport && obj.validIdentificationType) {
			/* prettier-ignore */
			return <AppInput required type="text" name={`${obj.name}.passport`} label="Passport" placeholder="e.g. VD1289281" disabled={obj.disabled?.passport} value={obj.values?.passport} error={obj.errors?.passport} touched={obj.touched?.passport} onFormat={formatPassportString} onChange={obj.onChange} />
		}

		return null;
	},[]);

	const onHandleGetSelectedBenefit = (obj) => {
		if (obj.requireRegenerate) return [];

		const benefits = obj.benefits;

		const selectedQuotation = quotationCheckout?.find((o) => o.insurer === obj.insurer);
		//prettier-ignore
		const selectedBenefitValue = Object.keys(benefits)?.map((o) => {
			if (benefits[o]) return o.replace("benefit_", "");
			return null;
		}).filter((o) => o);

		const benefitPayload = selectedQuotation?.extraBenefit?.filter((o) => {
			const found = selectedBenefitValue?.some((i) => i === o.benefitCode);
			return found;
		});

		const selectedAdditionalBenefits = benefitPayload?.map((o) => {
			switch (o?.benefitCode) {
				case COMMON.EXTRA_BENEFITS.DRIVERS_COVERAGE:
					return o?.benefitOption.find((i) => i.benefitCode === benefits?.benefit_AD_value);
				case COMMON.EXTRA_BENEFITS.WINDSCREEN_PROTECTION:
					return { ...o, benefitSumInsured: parseFloat(benefits?.benefit_005_value.toString().replaceAll(",", "")) };
				default:
					return o;
			}
		});

		return selectedAdditionalBenefits;
	};

	const onHandleSubmit = async (value) => {
		let response = null;

		const additionalDriverList = value?.additionalDriver?.map((o) => ({
			name: o.name,
			age: getDateOfBirthAge(o.dob),
			dateOfBirth: o.dob,
			identificationType: o.identificationType,
			identificationNumber: o.nric || o.passport,
			email: o.email,
			mobileNo: o.prefixNo + o.mobile,
			gender: o.gender,
			occupation: o.occupation,
			relation: o.relationship,
			experience: o.experience,
			maritalStatus: o.maritalStatus,
			mobile: o.mobile,
			prefixNo: o.prefixNo,
		}));

		const selectedAdditionalBenefits = onHandleGetSelectedBenefit(value);

		const isAdditionalDriver = selectedAdditionalBenefits?.some((i) => i?.benefitCode === COMMON.EXTRA_BENEFITS.ADDITIONAL_DRIVERS);

		const { requireRegenerate, ...obj } = value;
		
		let payload = {
			identificationType: enquiryInfo?.identificationType,
			identificationNo: enquiryInfo?.identificationNo,
			quotationId: quotationInfo?.quotationId,
			nvic: obj.nvicCode,
			provider: obj.insurer,
			sumInsured: parseFloat(obj.sumInsured.replace(",", "")),
			extraBenefitList: selectedAdditionalBenefits,
			selectedNvicCode: obj.nvicCode,
			additionalDriverList: isAdditionalDriver ? additionalDriverList : null,
			agreedValue: obj.agreedCoverage,
			vehicleBody: obj.vehicleBody,
			allianzNvic: obj.allianzVariant,
			allianzSumInsured: parseFloat(obj.allianzCoverage.toString().replace(",", "")),
			reconVehicle: obj.reconCar === "Yes" ? true : false,
		};

		if (requireRegenerate || formik.dirty) {
			if (requireRegenerate) {
				formik.setFieldValue("nvicCodeDisplay", payload.nvic);

				formik.setFieldValue("sumInsuredDisplay", formatCurrency(payload.sumInsured));

				formik.setFieldValue("vehicleBodyDisplay", payload.vehicleBody);

				formik.setFieldValue("allianzNvic", obj.allianzNvic);

				formik.setFieldValue("allianzVariantDisplay", obj.allianzVariantDisplay);

				formik.setFieldValue("allianzCoverage", obj.allianzCoverage);

				formik.setFieldValue("reconCar", obj.reconCar);

				formik.setFieldValue("marketCoverage", value.marketCoverage);

				formik.setFieldValue("agreedCoverage", value.agreedCoverage);

				regenerateModalRef.current.onHandleDismiss(nvicDropdown);

				payload = { ...payload, provider: "", extraBenefitList: [] };
			} else {
				payload = { ...payload, provider: "" };
			}

			try {
				onHandleStartLoading();

				if (requireRegenerate) {
					response = await api.post.motor.regenerateQuotation(payload);
				} else {
					response = await api.post.motor.recalculateQuotation(payload);
				}
			} catch (error) {
				switch (error?.response?.data?.errorCode) {
					case COMMON.ERRORS.GENERIC:
					case COMMON.ERRORS.NO_QUOTATION:
					case COMMON.ERRORS.QUOTATION_NOT_FOUND:
					case COMMON.ERRORS.QUOTATION_CALL_FAILED:
					case COMMON.ERRORS.QUOTATION_FAILED_RETRIEVE:
					case COMMON.ERRORS.COVER_NOTE_FAILED_RETRIEVE:
						dispatch(setQuotation({ ...quotationInfo, quotationCheckout: [] }));
						break;
					default:
						serveRequestErrors(error);
						break;
				}
			} finally {
				clearInterval(timerCounter.current);
				setLoading("");
				setIsLoading(false);
				formik.setSubmitting(false);
			}

			if (response) {
				const selectedResponseQuotation = response?.quotationCheckout?.find((o) => o.insurer === obj.insurer);

				const defaultResponseQuotation = response?.quotationCheckout[0];

				const responseQuotation = selectedResponseQuotation ?? defaultResponseQuotation;

				const newInsurer = selectedResponseQuotation?.insurer ?? defaultResponseQuotation?.insurer;

				const selectedQuotationNvic = responseQuotation?.nvic?.find((o) => o.nvic === obj.nvicCode);

				let originalBenefitsPayload = { benefits: {} };

				if (defaultResponseQuotation) {
					defaultResponseQuotation?.extraBenefit?.forEach((o) => {
						const benefitKey = `benefit_${o?.benefitCode}`;

						const windscreenProtection = o?.benefitCode === COMMON.EXTRA_BENEFITS.WINDSCREEN_PROTECTION;

						originalBenefitsPayload.benefits[benefitKey] = o.benefitIncluded;

						if (o?.benefitOption) {
							originalBenefitsPayload.benefits[benefitKey + "_value"] = o?.benefitOption?.[0]?.benefitCode;
						}

						if (windscreenProtection) {
							originalBenefitsPayload.benefits[benefitKey + "_value"] = 500;
						}
					});
				}

				if (requireRegenerate) {
					dispatch(
						setQuotation({
							...quotationInfo,
							...response,
							insurer: newInsurer,
							selectedNvicCode: obj.nvicCode,
							vehicleBody: obj.vehicleBody,
							selectedQuotation: responseQuotation,
							quotationNvic: selectedQuotationNvic,
							allianzNvic: obj.allianzVariant,
							allianzSumInsured: obj.allianzCoverage,
							reconVehicle: obj.reconCar,
						})
					);

					//prettier-ignore
					formik.resetForm({ values: { ...obj, isAllBenefit: false, insurer: newInsurer, nvicCodeDisplay: obj.nvicCode, sumInsuredDisplay: obj.sumInsured, totalPayable: selectedResponseQuotation?.totalPayable, vehicleBody: obj.vehicleBody, vehicleBodyDisplay: obj.vehicleBody, benefits: originalBenefitsPayload?.benefits, selectedQuotation: responseQuotation, quotationNvic: selectedQuotationNvic, } });
				} else {
					const nextSelectedQuotation = { ...responseQuotation, selectedExtraBenefit: selectedAdditionalBenefits };

					dispatch(setQuotation({ ...quotationInfo, ...response, insurer: newInsurer, selectedNvicCode: obj.nvicCode, selectedQuotation: nextSelectedQuotation, quotationNvic: selectedQuotationNvic }));

					formik.resetForm({ values: { ...obj, insurer: newInsurer, totalPayable: selectedResponseQuotation?.totalPayable, selectedQuotation: nextSelectedQuotation, quotationNvic: selectedQuotationNvic } });
				}
			} else {
				dispatch(setQuotation({ ...quotationInfo, quotationCheckout: [] }));

				formik.resetForm({ values: { ...obj, nvicCodeDisplay: obj.nvicCode, sumInsuredDisplay: obj.sumInsured, selectedQuotation: null, quotationNvic: null } });
			}
		} else {
			const newQuotationCheckout = quotationInfo?.quotationCheckout?.map((o) => {
				if (o.insurer === obj.insurer) return { ...o, additionalDriverList: payload.additionalDriverList, selectedExtraBenefit: selectedAdditionalBenefits };
				return o;
			});

			dispatch(setQuotation({ ...quotationInfo, ...payload, quotationCheckout: newQuotationCheckout, agreedValue: obj.agreedCoverage, isAllBenefit: obj.isAllBenefit }));

			formik.setSubmitting(false);

			onHandleNavigateApplicant();
		}
	};

	//prettier-ignore
	const CardSummedValue = useCallback((obj) => {
		const value = obj.formik.values;
		const touched = obj.formik.touched;
		const error = obj.formik.errors;
		const onHandleChange = obj.formik.handleChange;
		const valueType = value.marketCoverage ? 'market' : 'agreed';
		const minimumValue = value.marketCoverage ? value.minimumMarketValue : value.minimumAgreedValue;
		const maximumValue = value.marketCoverage ? value.maximumMarketValue : value.maximumAgreedValue;

		//prettier-ignore
		const headerClassName = (isActive) => {
			return classNames({
				"card-sum-insured__text": true,
				"card-sum-insured__text--active": isActive,
			});
		};
		
		return (
			<div className="card-sum-insured">
				<p className="card-sum-insured__header">{`Your vehicle's ${valueType} value range is RM${formatCurrency(minimumValue)} - RM${formatCurrency(maximumValue)}`}</p>
				
				<div className="card-sum-insured__container">
					<div className="card-sum-insured__wrapper">
						<p className="card-sum-insured__title">Coverage</p>

						<div className="card-sum-insured__button-wrapper" onClick={obj.onHandleOpenRegenerate}>
							<AppButton type="button" outline label="Edit" />
							<img src={editIcon} alt="edit" />
						</div>
					</div>

					<div className="card-sum-insured__header-wrapper">
						<p className={headerClassName(value.marketCoverage)}>Market Value</p>
						<p className={headerClassName(value.agreedCoverage)}>Agreed Value</p>
					</div>

					<div className="card-sum-insured__form">
						{/* prettier-ignore */}
						<AppInput type="text" name="sumInsuredDisplay" label="Sum Insured (RM)" placeholder="00.00" onFormat={formatCurrencyPattern} onChange={onHandleChange} value={value.sumInsuredDisplay} touched={touched.sumInsuredDisplay} error={error.sumInsuredDisplay} disabled />

						{/* prettier-ignore */}
						<AppInput type="text" name="nvicCodeDisplay" label="NVIC Code" value={value.nvicCodeDisplay} error={error.nvicCodeDisplay} touched={touched.nvicCodeDisplay} onChange={onHandleChange} disabled />
						
						{/* prettier-ignore */}
						<AppInput type="text" name="vehicleBodyDisplay" label="Vehicle Body" value={value.vehicleBodyDisplay} error={error.vehicleBodyDisplay} touched={touched.vehicleBodyDisplay} onChange={onHandleChange} disabled />
						
						{obj.hasAllianz && (
							<div className="card-sum-insured__box">			
								<div>
									<p className="card-sum-insured__title">Additional Information</p>
									<p className="card-sum-insured__description">(Applicable for Allianz Insurance only)</p>
								</div>

								<div className="card-sum-insured__button-wrapper" onClick={obj.onHandleAdditionalFieldsVisible}>
									<div className="app-mobile-input__icon MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSelect-icon MuiSelect-iconOutlined">
										<AppChevronIcon color="#677686" />
									</div>
								</div>
							</div>
						)}

						{obj.additionalFieldsVisible && (
							<div className="card-sum-insured__form">
								{/* prettier-ignore */}
								<AppInput type="text" name="allianzVariantDisplay" label="Allianz Variant" value={value.allianzVariantDisplay} error={error.allianzVariantDisplay} touched={touched.allianzVariantDisplay} onChange={onHandleChange} disabled />

								{/* prettier-ignore */}
								<AppInput type="text" name="allianzCoverage" label="Allianz Coverage (RM)" placeholder="Allianz Coverage" value={formatCurrency(value.allianzCoverage)} error={error.allianzCoverage} touched={touched.allianzCoverage} onChange={onHandleChange} disabled />
												
								{/* prettier-ignore */}
								<AppInput type="text" name="reconCar" label="Recon Car" value={value.reconCar} error={error.reconCar} touched={touched.reconCar} onChange={onHandleChange} disabled />
							</div>
						)}				
					</div>			
				</div>
			</div>
		);
	}, []);

	//prettier-ignore
	const CardAdditionalDriver = useCallback((obj) => {
		const values = obj.formik.values;
		const NotAdditionalDriver = values.benefits?.benefit_AD_value !== COMMON.EXTRA_BENEFITS.ADDITIONAL_DRIVERS;
		const NotDriverCoverage = !values.benefits?.benefit_AD;

		if (NotAdditionalDriver || NotDriverCoverage) return null;

		return (
			<FormikProvider value={obj.formik} >
				<FieldArray name="additionalDriver" render={(arrayHelper) => {
					const driverLength = arrayHelper.form.values.additionalDriver?.length;
					const isMaxDrivers = driverLength === 5;
					const disabled = Boolean(arrayHelper.form.isSubmitting || isMaxDrivers);

					const onHandleRemoveDriver = (index) => {
						if (driverLength <= 1) return;

						arrayHelper.remove(index);

						const additionalDrivers = [...document.querySelectorAll(".app-accordion-container")];

						if (driverLength - 1 <= 2) {
							additionalDrivers.forEach((o) => o.classList.add("app-accordion-container--active"));
						}
						else {
							additionalDrivers.forEach((o, i) => { if (additionalDrivers?.length -2 === i) o.classList.add("app-accordion-container--active"); });
						}
					};				
					
					const onHandleAddDriver = async () => {

						if (disabled) return;

						try {
							const fieldErrors = await arrayHelper.form.validateForm();

							if (!fieldErrors.additionalDriver) {
								arrayHelper.push(obj.additionalDriverFields);

								const expandIconItem = [...document.querySelectorAll(".main__arrow")];
								const additionalDrivers = [...document.querySelectorAll(".app-accordion-container")];

								if (driverLength !== 1) {
									additionalDrivers.forEach((o) => { o.classList.remove("app-accordion-container--active"); });
									expandIconItem.forEach((o) => { o.classList.remove("main__arrow--expand"); });
								}
							}
							else {
								arrayHelper.form.setTouched(setNestedObjectValues(fieldErrors, true));
							}
						}
						catch (error) {
							console.log(error);
						}
					};

					const onHandleChangeExpand = (index, toggle) => {
						const expandIconItem = [...document.querySelectorAll(".main__arrow")];
						const additionalDrivers = [...document.querySelectorAll(".app-accordion-container")];

						if (toggle) {
							additionalDrivers.forEach((o, i) => { if (index === i) o.classList.toggle("app-accordion-container--active"); });
						}
						else {
							additionalDrivers.forEach((o, i) => { if (index === i) o.classList.add("app-accordion-container--active"); });
						}

						expandIconItem.forEach((o, i) => { if (index === i) o.classList.toggle("main__arrow--expand"); });
					};

					return (
						<Fragment>
							{arrayHelper.form.values.additionalDriver?.map((o, i) => {
								const additionalDriver = arrayHelper.form.values.additionalDriver;
								const names = `additionalDriver[${i}]`;
								const additionalDriverValues = additionalDriver[i];
								const errors = arrayHelper.form.errors.additionalDriver?.[i];
								const touched = arrayHelper.form.touched.additionalDriver?.[i];
								const validIdentificationType = additionalDriverValues?.identificationType;
								const isPassport = additionalDriverValues?.identificationType === COMMON.IDENTIFICATION_TYPE.PASSPORT;

								const onHandleChange = (event) => {
									arrayHelper.form?.handleChange(event);
									onHandleChangeExpand(i, false);
								};

								const onHandleSetValue = (name, value) => {
									arrayHelper.form?.setFieldValue(name, value);
									onHandleChangeExpand(i, false);
								}
								
								return (
									<AppAccordionContainer key={i} title={`Additional ${COMMON.NUMBERS[i]} Driver`} description="Fill in additional driver information" onHandleRemove={() => onHandleRemoveDriver(i)} onHandleChangeExpand={() => onHandleChangeExpand(i, true)} length={additionalDriver?.length}>
										<div className="card-additional-driver">
											<div className="driver-main__header-input">
												{/* prettier-ignore */}
												<AppInput required type="text" maxLength={100} name={`${names}.name`} label="Name" placeholder="e.g. Lee Kah Chun" value={additionalDriverValues?.name} error={errors?.name} touched={touched?.name} onChange={onHandleChange} />
			
												{/* prettier-ignore */}
												<AppSelectInput required type="text" name={`${names}.identificationType`} label="ID Type" placeholder="Please Select" loadOptions={getIdentificationListing} value={additionalDriverValues.identificationType} error={errors?.identificationType} touched={touched?.identificationType} onChange={onHandleChange} searchable={false} />
			
												{/* prettier-ignore */}
												<IdentificationField formik={arrayHelper.form} name={names} values={additionalDriverValues} errors={errors} touched={touched} onChange={onHandleChange} isPassport={isPassport} validIdentificationType={validIdentificationType} index={i}/>
			
												{/* prettier-ignore */}
												<AppCalendarInput required name={`${names}.dob`} label="Date of Birth" placeholder="e.g. 16 July 1994" maxDate={new Date()} displayFormat={calendarDisplayFormat} value={additionalDriverValues.dob} error={errors?.dob} touched={touched?.dob} onChange={onHandleSetValue} disabled={!isPassport}/>
											</div>
											
											<div className="driver-main__body-input">
												{/* prettier-ignore */}
												<AppInput required type="text" maxLength={100} name={`${names}.email`} label="Email Address" placeholder="e.g. username@email.com" value={additionalDriverValues.email} error={errors?.email} touched={touched?.email} onChange={onHandleChange} />
			
												{/* prettier-ignore */}
												<AppMobileInput required type="number" maxLength={15} name={`${names}.mobile`} prefixNoName={`${names}.prefixNo`} label="Mobile No." placeholder="e.g. 121231234" value={additionalDriverValues.mobile} prefixNo={additionalDriverValues.prefixNo} error={errors?.mobile} touched={touched?.mobile} onChange={onHandleChange} onChangeCode={onHandleSetValue} />
			
												{/* prettier-ignore */}
												<AppButtonInput required name={`${names}.gender`} label="Gender" value={additionalDriverValues.gender} error={errors?.gender} touched={touched?.gender} onChange={onHandleSetValue} options={COMMON.DROPDOWNS.GENDER} />
			
												{/* prettier-ignore */}
												<AppSelectInput required type="text" name={`${names}.occupation`} label="Occupation" placeholder="Please Select" loadOptions={getOccupationListing} value={additionalDriverValues.occupation} error={errors?.occupation} touched={touched?.occupation} onChange={onHandleChange} />
											
												{/* prettier-ignore */}
												<AppSelectInput required type="text" name={`${names}.maritalStatus`} label="Marital Status" placeholder="Please Select" loadOptions={getMaritalStatusListing} value={additionalDriverValues.maritalStatus} error={errors?.maritalStatus} touched={touched?.maritalStatus} onChange={onHandleChange} />
											
												{/* prettier-ignore */}
												<AppSelectInput required type="text" name={`${names}.relationship`} label="Relationship" placeholder="Please Select" loadOptions={getRelationshipListing} value={additionalDriverValues.relationship} error={errors?.relationship} touched={touched?.relationship} onChange={onHandleChange} />
			
												{/* prettier-ignore */}
												<AppInput required type="number" maxLength={2} name={`${names}.experience`} label="Driving Experience (Years)" placeholder="e.g. 10" value={additionalDriverValues.experience} error={errors?.experience} touched={touched?.experience} onChange={onHandleChange} />
											</div>
										</div>
									</AppAccordionContainer>
								);
							})}
			
							<div className="card-additional-driver__footer">
								<div type="button" className="card-additional-driver__button" onClick={onHandleAddDriver}>
									<AppButton type="button" outline label="Add Driver" icon={addIcon} disabled={disabled} />
									<span className="card-additional-driver__note">maximum 5 drivers</span>
								</div>

								{isMaxDrivers && <p className="card-additional-driver__error">You have maxed with 5 additional drivers</p>}
							</div>
						</Fragment>
					);
				}} />
			</FormikProvider>
		)}, []);

	const QuotationStatus = useCallback((obj) => {
		if (obj.isLoading) {
			return (
				<div className="card-insurer__body">
					<img className="card-insurer__clock-icon" src={clockIcon} alt="processing" />

					<p className="card-insurer__title">{`Processing${obj.loadingCount}`}</p>
					<p className="card-insurer__subtitle">Please wait for awhile</p>
				</div>
			);
		} else if (obj.noQuotationFound) {
			return (
				<div className="card-insurer__body">
					<img className="card-insurer__empty-box-icon" src={emptyIcon} alt="empty" />

					<p className="card-insurer__description">No Insurer Found</p>
					<p className="card-insurer__subtitle">Kindly re-enter your sum insured again or kindly contact our digital support consultant for assistance.</p>
					<a className="card-insurer__sub-description" href="mailto: easycover@support.com">
						easycover@support.com
					</a>
				</div>
			);
		} else {
			return null;
		}
	}, []);

	//prettier-ignore
	const QuotationCard = useCallback((obj) => {
		let quotation = null;

		if (obj.filter.sort) {
			if (obj.filter.sort === "High to Low") {
				quotation = obj.quotationCheckout.sort(desc);
			} else {
				quotation = obj.quotationCheckout.sort(asc);
			}
		} else {
			quotation = obj.quotationCheckout;
		}

		quotation = quotation?.filter((o) => {
			const gotFilter = obj.filter.insurerList?.length && obj.filter.insurerList.includes(o.insurer);
			const noFilter = !obj.filter.insurerList?.length;

			if (gotFilter || noFilter) return o;

			return null;
		});

		return quotation.map((o) => {
			const onChange = () => obj.onHandleChecked(o.insurer);

			o = { ...o, grossPremium: o.grossPremium?.toString(), minimumMarketValue: o.minimumMarketValue?.toString(), maximumMarketValue: o.maximumMarketValue?.toString(), minimumAgreedValue: o.minimumAgreeValue?.toString(), maximumAgreedValue: o.maximumAgreeValue?.toString(), sumInsured: o.sumInsured?.toString(), marketCoverage: obj.marketCoverage };

			return <AppCard key={o.insurer} required name={o.insurer} onChange={onChange} checked={obj.insurerValue === o.insurer} data={o} />;
		});
	}, []);

	//prettier-ignore
	const AdditionalBenefits = useCallback((obj) => {
			const selectedQuotation = obj?.quotation?.filter((o) => o.insurer === obj.formik.values.insurer)?.[0];
			const extraBenefits = selectedQuotation?.extraBenefit;

			const onHandleRoundedHundred = (event) => {
				const value = event?.target?.value.replace(",", "");
				const rounded = Math.round(+value / 100) * 100;
				
				if (rounded < 500) {
					event.target.value = formatCurrency(500);
				}
				else if(!isNaN(rounded)){
					event.target.value = formatCurrency(rounded);
				}

				return event;
			};

			const onHandleKeyDown = (event) => {
				const cursorPosition = event.target.selectionEnd;
				const value = event.target.value;

				if(cursorPosition !== value.length) {
					event.target.selectionEnd = value.length;
					event.target.selectionStart = value.length;
					event.preventDefault();
				}

				if (event.key.length === 1 && !event.key.match(/\d/i)) {
                    event.preventDefault();
                }
			}

			const benefits = extraBenefits?.map((o) => {
				const benefitName = `benefit_${o.benefitCode}`;
				const benefitValue = `${benefitName}_value`
				const value = obj.formik.values.benefits;
				const error = obj.formik.errors.benefits;
				const touched = obj.formik.touched.benefits;
				const disabled = obj.formik.isSubmitting || o.benefitIncluded

				const checkbox = <AppCheckbox required name={benefitName} logo={o.logo} onClick={obj.onHandleCheckbox} label={o.benefitDescription} value={value[benefitName]} error={error?.benefitName} touched={touched?.benefitName} disabled={disabled} />;
				
				switch (o.benefitCode) {
					case COMMON.EXTRA_BENEFITS.DRIVERS_COVERAGE:
						const driverTypeOptions = o.benefitOption?.map((e) => ({ value: e.benefitCode, label: e.benefitDescription }));
						
						return (
							<div className="card-benefits__item" key={o.benefitCode}>
								{checkbox}

								{value[benefitName] && (
									<Fragment>
										<AppSelectInput name={benefitValue} label="" placeholder="Please Select" options={driverTypeOptions} value={value[benefitValue]} error={error?.benefitValue} touched={touched?.benefitValue} onChange={obj.onHandleValue} disabled={obj.formik.isSubmitting} />

										<p className="card-benefits__alert-text">Selected insurer comes with complimentary all driver coverage</p>
									</Fragment>
								)}
							</div>
						);
					case COMMON.EXTRA_BENEFITS.WINDSCREEN_PROTECTION:
						return (
							<div className="card-benefits__item" key={o.benefitCode}>
								{checkbox}

								{value[benefitName] && (
									<Fragment>
										<AppInput type="text" name={benefitValue} label="" placeholder="00.00" value={value[benefitValue]} error={error?.[benefitValue]} touched={touched?.[benefitValue]} onChange={obj.onHandleValue} disabled={obj.formik.isSubmitting} onKeyDown={onHandleKeyDown} onFormat={formatCurrencyPattern} onBlur={onHandleRoundedHundred}/>

										{!error?.[benefitValue] && <p className="card-benefits__text">Minimum RM500</p>}
									</Fragment>
								)}
							</div>
						);
					default:
						return(
							<div className="card-benefits__item" key={o.benefitCode}>
								{checkbox}
							</div>
						)
				}
			});

			return benefits;
	},[]);

	const asc = (a, b) => {
		return a?.grossPremium - b?.grossPremium;
	};

	const desc = (a, b) => {
		return b?.grossPremium - a?.grossPremium;
	};

	const onHandleCheckEnterKey = (event) => {
		const code = event.keyCode ? event.keyCode : event.which;

		if (code === 13) {
			event.cancelBubble = true;
			event.returnValue = false;

			if (event.stopPropagation) {
				event.stopPropagation();
				event.preventDefault();
			}

			return false;
		}
	};

	return (
		<div className="page-quotation">
			<div className="quotation">
				<form className="quotation__form" onSubmit={formik.handleSubmit} onKeyPress={onHandleCheckEnterKey}>
					<p className="quotation__title">Quotation Details</p>
					<div className="quotation__container">
						<div className="quotation__wrapper">
							{/* prettier-ignore */}
							<CardSummedValue formik={formik} vehicleInformation={vehicleInformation} onHandleOpenRegenerate={onHandleOpenRegenerate} hasAllianz={quotationInfo?.hasAllianz} additionalFieldsVisible={additionalFieldsVisible} onHandleAdditionalFieldsVisible={onHandleAdditionalFieldsVisible} />

							<AppCardInfo data={VehicleData} />
						</div>

						<div className="quotation__wrapper">
							{/* prettier-ignore */}
							<AppCardContainer title="Step 1" description="Select your preferred Insurer" subTitle={totalQuotationDescription} subDescription="Insurers Found">
								<AppSearchInput ref={searchInputRef} multiValues={filter} onRemoveField={onHandleRemoveField} disabled={isLoading}>
									<form className="app-advance-search-form">
										<div className="advance-form">
											<div className="advance-form__header">
												<button type="button" className="advance-form__remove" onClick={onHandleCloseSearch}>
													<AppCloseIcon color="#666666" />
												</button>
											</div>

											<div className="advance-form__inputs">
												<AppMultiSelectInput name="insurerList" label="Insurer" placeholder="e.g. AIA" options={libertyListOptions} value={formik.values.insurerList} onChange={onHandleInsurerList} />
												<AppSelectInput name="sort" label="Sort" placeholder="Premium Amount : Low to High" options={COMMON.DROPDOWNS.SORT} value={formik.values.sort} onChange={formik.handleChange} />
											</div>

											<div className="advance-form__button-container">
												<AppButton type="button" label="Clear" outline onClick={onHandleResetSearch} />
												<AppButton type="button" label="Filter" onClick={onHandleSearch} />
											</div>
										</div>
									</form>
								</AppSearchInput>

								{!isLoading && !!quotationCheckout?.length && <div className="card-insurer__wrapper">
									<QuotationCard filter={filter} quotationCheckout={quotationCheckout} onHandleChecked={onHandleChecked} insurerValue={formik.values.insurer} marketCoverage={formik.values.marketCoverage} />
								</div>}

								<div className="card-insurer__error-wrapper">
									<QuotationStatus insurerData={quotationCheckout} loadingCount={loading} isLoading={isLoading} formik={formik} noQuotationFound={!quotationCheckout?.length} />
								</div>
							</AppCardContainer>

							{/* prettier-ignore */}
							{!isLoading && !!quotationCheckout?.length && (
								<Fragment>
									{/* prettier-ignore */}
									<AppCardContainer title="Step 2" description="Select additional benefits" subDescription="Select All Benefits" onHandleSwitch={onHandleSwitch} isChecked={formik?.values?.isAllBenefit} disabled={formik.isSubmitting}>
										<div className="card-benefits__wrapper">
											<AdditionalBenefits quotation={quotationCheckout} formik={formik} onHandleCheckbox={onHandleCheckbox} onHandleValue={onHandleValue} />
										</div>
									</AppCardContainer>

									<CardAdditionalDriver formik={formik} additionalDriverFields={additionalDriverFields} />

									<div className="card-total-quotation">
										<div className="total-quotation">
											<div className="total-quotation__wrapper">
												<p className="total-quotation__title">Total Quotation</p>
												<div className="recalculate-button">
													{formik.dirty && (
														<Fragment>
															<div className="recalculate-button__wrapper">
																<AppButton type="submit" label="Recalculate" disabled={formik.isSubmitting} icon={refreshIcon} />
															</div>

															<p className="recalculate-button__text">Click recalculate button to get latest total quotation</p>
														</Fragment>
													)}
												</div>
											</div>

											<div className="total-quotation__wrapper">
												<p className="total-quotation__price">{`RM${formatCurrency(selectedQuotationTotalPayable)}`}</p>
												<p className="total-quotation__description">per annual</p>
											</div>
										</div>
									</div>
								</Fragment>
							)}

							<div className="quotation__button-container">
								<AppButton outline type="button" label="Back" disabled={isLoading} onClick={onHandleNavigateEnquiry} />
								<AppButton type="submit" label="Next" disabled={isLoading || !quotationCheckout?.length} />
							</div>
						</div>
					</div>
				</form>
			</div>

			{/* prettier-ignore */}
			<AppRegenerateModal ref={regenerateModalRef} onHandleSubmit={onHandleSubmit} nvicDropdown={nvicDropdown} hasAllianz={quotationInfo?.hasAllianz} allianzMarket={quotationInfo?.allianzMarket} allianzAgree={quotationInfo?.allianzAgree} />
		</div>
	);
};

export default PageQuotation;
