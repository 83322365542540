import React, { memo, useRef, useMemo, useState, useCallback, Fragment } from "react";
import { FormControl, TextField, InputAdornment } from "@mui/material";
import PropTypes from "prop-types";
import { debounce } from "lodash";

import AppIcon from "components/app-icon";
import classNames from "common/class-names";
import AppCloseIcon from "components/icons/app-close-icon";
import lockIcon from "assets/images/components/app-input/lock-icon.svg";
import eyeOpenIcon from "assets/images/components/app-input/eye-open-icon.svg";
import eyeCloseIcon from "assets/images/components/app-input/eye-close-icon.svg";

const AppInput = ({ onChange, onFormat, onBlur, onClear, onKeyDown, multilineRow = 8, ...props }) => {
	const [passwordVisible, setPasswordVisible] = useState(false);
	const type = useMemo(() => props.type, [props.type]);
	const textFieldRef = useRef();
	const [displayClearIcon, setDisplayClearIcon] = useState(!!props.defaultValue);
	const isPasswordField = useMemo(() => type === "password", [type]);
	const isErrorField = useMemo(() => !!props.error && !!props.touched, [props.error, props.touched]);
	const errorMessage = useMemo(() => (isErrorField ? props.error : ""), [props.error, isErrorField]);
	const inputType = useMemo(() => {
		if ((isPasswordField && passwordVisible) || type === "number") return "text";
		else return type;
	}, [isPasswordField, passwordVisible, type]);

	const className = useMemo(() => {
		return classNames({
			"app-input": true,
			"app-input--disabled": props.disabled,
			"app-input--multiline": props.multiline,
			...(props.className && {
				[props.className]: true,
			}),
		});
	}, [props.className, props.disabled, props.multiline]);

	const onTogglePasswordVisibility = useCallback(() => {
		setPasswordVisible((prev) => !prev);
	}, []);

	//prettier-ignore
	const onHandleKeyDown = useCallback((event) => {
		if(type === "number") {
			switch (event.key) {
				case "e":
				case "-":
				case "+":
				case ".":
					event.preventDefault();
					break;
				default:
					break;
			}
		}

		if(onKeyDown) onKeyDown(event);
	}, [type, onKeyDown]);

	//prettier-ignore
	const onHandleClearIconDisplay = useCallback((event) => {
		const trimmedValue = event.target.value.trim();

		if(!onClear) return;

		setDisplayClearIcon(!!trimmedValue);
	}, [onClear]);

	//prettier-ignore
	const onHandleChange = useCallback((event) => {
		onHandleClearIconDisplay(event);

		if(type === "number") {
			event.target.value = event.target.value.replace(/[^\d]/g, "");
			onChange(event);
		}
		else if (onFormat) {
			const enrichedEvent = onFormat(event);
			onChange(enrichedEvent);
		} else {
			onChange(event);
		}
	}, [onChange, onFormat, type, onHandleClearIconDisplay]);

	//prettier-ignore
	const onHandleBlur = useCallback((event) => {
		event.target.value = event.currentTarget.value.trim();

		if (onBlur) {
			const nextEvent = onBlur(event);
			onChange(nextEvent);
		}
		else {
			onChange(event);
		}
	}, [onChange, onBlur]);

	//prettier-ignore
	const onHandleClear = useCallback(() => {
		textFieldRef.current.value = "";
		setDisplayClearIcon(false);
		onClear();
	}, [onClear]);

	const onHandleDebounceClear = debounce(onHandleClear, 500);

	const InputProps = useMemo(() => {
		const inputProps = {};

		if (isPasswordField) {
			const endAdornment = {
				endAdornment: (
					<InputAdornment position="end">
						<button type="button" className="app-input__password-toggle" onClick={onTogglePasswordVisibility}>
							{passwordVisible ? <AppIcon src={eyeOpenIcon} /> : <AppIcon src={eyeCloseIcon} />}
						</button>
					</InputAdornment>
				),
			};

			Object.assign(inputProps, endAdornment);
		} else if (onClear && displayClearIcon) {
			const endAdornment = {
				endAdornment: (
					<InputAdornment position="end">
						<button type="button" className="app-input__icon-button" onClick={onHandleDebounceClear}>
							<AppCloseIcon color="#666666" />
						</button>
					</InputAdornment>
				),
			};

			Object.assign(inputProps, endAdornment);
		}

		if (props.icon) {
			const startAdornment = {
				startAdornment: (
					<InputAdornment position="start">
						<AppIcon src={props.icon} />
					</InputAdornment>
				),
			};

			Object.assign(inputProps, startAdornment);
		}

		if (props.disabled) {
			const endAdornment = {
				endAdornment: (
					<InputAdornment position="end">
						<AppIcon src={lockIcon} />
					</InputAdornment>
				),
			};

			Object.assign(inputProps, endAdornment);
		}

		return inputProps;
	}, [isPasswordField, passwordVisible, props.disabled, props.icon, displayClearIcon, onClear, onHandleDebounceClear, onTogglePasswordVisibility]);

	const Footer = useCallback(() => {
		return (
			<Fragment>
				{errorMessage} {props.multiline && <span className="app-input__count">{`${props.value.length}/${props.maxLength}`}</span>}
			</Fragment>
		);
	}, [errorMessage, props.multiline, props.value, props.maxLength]);

	return (
		<div className={className}>
			<FormControl error={isErrorField}>
				<label className="app-input__label" htmlFor={props.name}>
					{props.label}
					{props.required && <span className="app-input__required">*</span>}
				</label>

				{/*prettier-ignore*/}
				<TextField inputRef={textFieldRef} autoComplete="off" multiline={props.multiline} rows={props.multiline ? multilineRow : 1} defaultValue={props.defaultValue} value={props.value} type={inputType} name={props.name} error={isErrorField} helperText={<Footer />} disabled={props.disabled} placeholder={props.placeholder} onChange={onHandleChange} onKeyDown={onHandleKeyDown} onBlur={onHandleBlur} inputProps={{ maxLength: props.maxLength }} InputProps={InputProps} />
			</FormControl>
		</div>
	);
};

AppInput.propTypes = {
	error: PropTypes.string,
	label: PropTypes.string,
	disabled: PropTypes.bool,
	required: PropTypes.bool,
	onFormat: PropTypes.func,
	className: PropTypes.string,
	maxLength: PropTypes.number,
	disabledTrim: PropTypes.bool,
	placeholder: PropTypes.string,
	multilineRow: PropTypes.number,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	onClear: PropTypes.func,
	type: PropTypes.oneOf(["text", "password", "number"]).isRequired,
};

export default memo(AppInput);
