import React, { memo, useImperativeHandle, forwardRef, useState, useCallback } from "react";
import PropTypes from "prop-types";
import Menu from "@mui/material/Menu";

import AppIcon from "components/app-icon";
import AppButton from "components/app-button";
import FilterIcon from "assets/images/filter-icon.svg";
import AppCloseIcon from "components/icons/app-close-icon";

const AppSearchInput = ({ children, ...props }, ref) => {
	const [anchorEl, setAnchorEl] = useState(null);

	const onhandleCloseAdvanceSearch = useCallback(() => {
		setAnchorEl(null);
	}, []);

	const onHandleShowAdvanceSearch = useCallback((event) => {
		if(props.disabled) return;
		
		setAnchorEl(event.currentTarget);
	}, [props.disabled]);

	//prettier-ignore
	const onHandleRemoveField = useCallback((event, field, fieldValue) => {
		event.stopPropagation();
		props.onRemoveField(field, fieldValue);
	}, [props]);

	const Pills = useCallback(() => {
		const items = Object.keys(props.multiValues).map((o, i) => {
			const isString = typeof props.multiValues?.[o] === "string" && props.multiValues?.[o];
			const isArray = Array.isArray(props.multiValues[o]) && props.multiValues?.[o]?.length;

			if (!isString && !isArray) return null;

			if (isString) {
				return (
					<div className="advance-search__pill" key={i}>
						<p className="advance-search__value">{props.multiValues[o]} </p>
						<button className="advance-search__remove" onClick={(event) => onHandleRemoveField(event, o)}>
							<AppCloseIcon color="#666666" />
						</button>
					</div>
				);
			}

			if (props.multiValues?.[o]?.length) {
				return props.multiValues[o].map((obj, index) => {
					return (
						<div className="advance-search__pill" key={index}>
							<p className="advance-search__value">{obj} </p>
							<button className="advance-search__remove" onClick={(event) => onHandleRemoveField(event, o, obj)}>
								<AppCloseIcon color="#666666" />
							</button>
						</div>
					);
				});
			}

			return null;
		});

		return items;
	}, [props.multiValues, onHandleRemoveField]);

	useImperativeHandle(ref, () => ({
		onhandleCloseAdvanceSearch: onhandleCloseAdvanceSearch,
	}));

	return (
		<div className="app-search-input">
			<div className="search-input">
				<div className="advance-search" onClick={onHandleShowAdvanceSearch}>
					<AppIcon src={FilterIcon} />
					<p className="advance-search__text">Filter</p>
					<Pills />
				</div>
			</div>

			{children && (
				<Menu
					classes={{ root: "app-search-menu" }}
					anchorEl={anchorEl}
					open={!!anchorEl}
					onClose={onhandleCloseAdvanceSearch}
					anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
					transformOrigin={{ vertical: "top", horizontal: "left" }}
				>
					{children}
				</Menu>
			)}

			{props.buttons && !props.hideButton && (
				<div className="app-search-input__button-container">
					{props.buttons?.map((o, i) => (
						<AppButton key={i} type="button" {...o} label={o.label} icon={o.icon} outline={o.outline} onClick={o.onClick} />
					))}
				</div>
			)}
		</div>
	);
};

export default memo(forwardRef(AppSearchInput));

const buttonPropsType = (props, propsName, components) => {
	const propsType = {
		buttonIcon: PropTypes.string.isRequired,
		buttonLabel: PropTypes.string.isRequired,
		onButtonClick: PropTypes.func.isRequired,
	};

	if (props.buttonIcon || props.buttonLabel || props.onButtonClick) return PropTypes.checkPropTypes(propsType, props, propsName, components);

	return null;
};

const advanceSearchPropType = (props, propsName, components) => {
	const propsType = {
		multiValues: PropTypes.object.isRequired,
		onRemoveField: PropTypes.func.isRequired,
	};

	if (props.multiValues || props.onRemoveField) return PropTypes.checkPropTypes(propsType, props, propsName, components);

	return null;
};

AppSearchInput.propTypes = {
	multiValues: advanceSearchPropType,
	onRemoveField: advanceSearchPropType,
	disabled: PropTypes.bool,
	onClear: PropTypes.func,
	onChange: PropTypes.func,
	buttonIcon: buttonPropsType,
	buttonLabel: buttonPropsType,
	onButtonClick: buttonPropsType,
};
