const formatCurrencyPattern = (event) => {
	const original = event.target.value;
	const value = original.replace(/[^0-9.]+/g, "");

	if (parseFloat(value)) {
		if (value.length === 1) {
			event.target.value = "00.0" + value;
			return event;
		}

		let arrayNumbers = value.split(".");

		if (arrayNumbers.length === 1) {
			const last2Digit = arrayNumbers[0].slice(-2);

			arrayNumbers[0] = arrayNumbers[0].slice(0, -2);

			arrayNumbers[1] = last2Digit;
		}

		const decimal = arrayNumbers[1];
		const number = arrayNumbers[0];

		if (decimal && decimal.length > 2) {
			/* 00.111 > 001.11 */
			/* Move 3rd decimal to number */
			const array = arrayNumbers[1].split("");

			const firstItem = array.shift();

			arrayNumbers[1] = array.join("");

			arrayNumbers[0] = number + firstItem;
		} else if (decimal && decimal.length === 1) {
			const lastNumber = number.slice(number.length - 1);
			const newNumber = number.slice(0, number.length - 1);

			arrayNumbers[1] = lastNumber + decimal;

			arrayNumbers[0] = newNumber.toString().padStart(2, "0");
		}

		if (arrayNumbers[0].length > 1) {
			/* 001.11 > 01.11 */
			/* Remove 3rd number if equal to 0 */
			const array = arrayNumbers[0].split("");

			if (arrayNumbers[0].length >= 2 && array[0] === "0") array[0] = "";

			arrayNumbers[0] = array.join("");
		}

		arrayNumbers[0] = arrayNumbers[0].replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");

		const updatedValues = arrayNumbers.join(".");

		event.target.value = updatedValues.padStart(5, "0");

		event.target.selectionEnd = event.target.value.length + 1;

		return event;
	} else {
		event.target.value = "00.00";
	}

	return event;
};

export const formatCurrency = (price = 0) => {
	if (price?.length === 0 || price === null) return parseFloat(0).toFixed(2);

	const currency = parseFloat(price).toFixed(2);

	return currency.toString().replace(/\d(?=(\d{3})+\.)/g, "$&,");
};

export default formatCurrencyPattern;
