import React, { useCallback, useMemo, memo } from "react";

import classNames from "common/class-names";
import { CALENDAR_FORMAT, getMonths } from "common/calendar";

const AppMonths = (props) => {
	const months = useMemo(() => getMonths(CALENDAR_FORMAT.MONTH_FORMAT), []);
	const current = useMemo(() => new Date(props.value).getMonth() + 1, [props]);

	//prettier-ignore
	const onChange = useCallback((value) => {
		props.onChange(value);
	}, [props]);

	return (
		<div className="app-months">
			<ul className="months" id="months">
				{months.map((o, i) => {
					const isDisabled = o.isDisabled;
					const isCurrentMonth = o.thisMonth;
					const isActive = o.value === current;

					const itemClassNames = classNames({
						months__item: true,
						"months__item--disabled": isDisabled,
						"months__item--current": isCurrentMonth,
						"months__item--active": isActive && !isDisabled,
					});

					return (
						<li key={i} className={itemClassNames} onClick={() => onChange(o.value)}>
							<div className="months__month">
								<span className="months__number">{o.value}</span>
								<span className="months__text">{o.month}</span>
							</div>
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default memo(AppMonths);
