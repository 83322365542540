import { redirect } from "react-router-dom";

import COMMON from "common";
import store from "store/store";
import pathnames from "routes/pathnames";

export const protectedRoutes = ({ request, params }) => {
	const token = localStorage.getItem(COMMON.AUTH_TOKEN) || sessionStorage.getItem(COMMON.AUTH_TOKEN);

	if (token) return true;

	return redirect(pathnames.login);
};

export const publicRoutes = () => {
	const token = localStorage.getItem(COMMON.AUTH_TOKEN) || sessionStorage.getItem(COMMON.AUTH_TOKEN);

	if (token) return redirect(pathnames.dashboard);
	else return true;
};

export const quotationRoutes = () => {
	const quotation = store.getState()?.motor?.quotation;
	
	if (!quotation) return redirect(pathnames.home);

	else return true;
};
