import React, { useMemo, useCallback, Fragment, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import COMMON from "common";
import api from "services/api";
import ERRORS from "common/errors";
import pathnames from "routes/pathnames";
import { getDateOfBirthNRIC } from "common/nric-helpers";
import serveRequestErrors from "common/serve-request-errors";
import { setEnquiry, setQuotation, setReferralCode } from "store/slices/motor";
import formatVehicleString from "common/format-vehicle-string";
import formatPassportString from "common/format-passport-string";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppSelectInput from "components/app-select-input";
import AppMaskingInput from "components/app-masking-input";
import AppLoading from "components/pages/page-enquiry/app-loading";
import AppCardStatus from "components/pages/page-enquiry/app-card-status";
import getIdentificationListing from "services/get-identification-listing";

const PageEnquiry = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const motor = useSelector((state) => state.motor);
	const [messageType, setMessageType] = useState("");
	const [errorCode, setErrorCode] = useState("");
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const referralCode = urlParams.get("myReferralCode") || "";

	const initialValues = useMemo(() => {
		const info = motor?.enquiry;
		const isInfoPassport = info?.identificationType === COMMON.IDENTIFICATION_TYPE.PASSPORT;

		let payload = {
			identificationType: "NRIC",
			passport: "",
			nric: process.env.REACT_APP_ID,
			vehicleRegNo: process.env.REACT_APP_VEHICLE,
			postcode: process.env.REACT_APP_POSTCODE,
			privacy: false,
			tnc: false,
		};

		if (info) {
			payload.tnc = true;

			payload.privacy = true;

			if (info.identificationType) payload.identificationType = info.identificationType;

			if (info.identificationNo) {
				if (isInfoPassport) {
					payload.passport = info.identificationNo;
				} else {
					payload.nric = info.identificationNo;
				}
			}

			if (info.vehicleRegistrationNo) payload.vehicleRegNo = info.vehicleRegistrationNo;

			if (info.postcode) payload.postcode = info.postcode;
		}

		return payload;
	}, [motor]);

	const formik = useFormik({
		initialValues,
		validationSchema: yup.object({
			identificationType: yup.string().required(ERRORS.REQUIRED),
			passport: yup.string().when(["identificationType"], {
				is: (identificationType) => identificationType === COMMON.IDENTIFICATION_TYPE.PASSPORT,
				then: () => yup.string().required(ERRORS.REQUIRED),
			}),
			nric: yup.string().when(["identificationType"], {
				is: (identificationType) => identificationType === COMMON.IDENTIFICATION_TYPE.NRIC,
				then: () => yup.string().required(ERRORS.REQUIRED).min(14, ERRORS.NRIC),
			}),
			vehicleRegNo: yup.string().required(ERRORS.REQUIRED),
			postcode: yup.number().required(ERRORS.REQUIRED),
			tnc: yup.boolean().oneOf([true], ERRORS.REQUIRED),
			privacy: yup.boolean().oneOf([true], ERRORS.REQUIRED),
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		},
	});

	const isPassport = useMemo(() => formik.values.identificationType === COMMON.IDENTIFICATION_TYPE.PASSPORT, [formik.values.identificationType]);
	const validIdentificationType = useMemo(() => !!formik.values.identificationType, [formik.values.identificationType]);

	const onHandleNavigateQuotation = useCallback(() => {
		navigate(pathnames.quotation);
	}, [navigate]);

	const onHandleSubmit = async (values) => {
		let response = null;

		try {
			let payload = {
				identificationType: values.identificationType,
				identificationNo: isPassport ? values.passport : values.nric,
				vehicleRegistrationNo: values.vehicleRegNo,
				postcode: values.postcode,
				dateOfBirth: isPassport ? null : getDateOfBirthNRIC(values.nric),
			};
			dispatch(setEnquiry(payload));
			response = await api.post.motor.quotation(payload);
		} catch (error) {
			switch (error?.response?.data?.errorCode) {
				case COMMON.ERRORS.POLICY_NOT_EXPIRED:
					setMessageType(COMMON.MESSAGE_TYPE.RENEW);
					setErrorCode(error?.response?.data?.exceptionMessage);
					break;
				case COMMON.ERRORS.GENERIC:
				case COMMON.ERRORS.VIX_CALL_FAILED:
				case COMMON.ERRORS.VEHICLE_STATUS_FAILED:
				case COMMON.ERRORS.QUOTATION_FAILED_RETRIEVE:
				case COMMON.ERRORS.POLICY_EXPIRED_LONG_PERIOD:
				case COMMON.ERRORS.QUOTATION_CALL_FAILED:
				case COMMON.ERRORS.QUOTATION_NOT_FOUND:
				case COMMON.ERRORS.NO_QUOTATION:
				case COMMON.ERRORS.COVER_NOTE_FAILED_RETRIEVE:
					setMessageType(COMMON.MESSAGE_TYPE.INVALID);
					setErrorCode(error?.response?.data?.errorCode + " " + error?.response?.data?.exceptionMessage);
					break;
				case COMMON.ERRORS.POSTCODE_NOT_FOUND:
					formik.setFieldError("postcode", error?.response?.data?.exceptionMessage);
					break;
				default:
					if (!error?.response?.data?.errorCode) {
						if (error?.code === COMMON.ERRORS.NETWORK_ERROR) {
							setMessageType(COMMON.MESSAGE_TYPE.NETWORK);
						} else if (error?.code === COMMON.ERRORS.BE_BAD_RESPONSE) {
							setMessageType(COMMON.MESSAGE_TYPE.INVALID);
						}
					} else {
						serveRequestErrors(error);
					}
					break;
			}
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			if (referralCode && referralCode !== "null") dispatch(setReferralCode(referralCode));

			dispatch(setQuotation(response));
			onHandleNavigateQuotation();
		}
	};

	//prettier-ignore
	const onHandleTnc = useCallback((event) => {
		const value = event.currentTarget.checked
		formik.setFieldValue("tnc", !value);
	}, [formik]);

	//prettier-ignore
	const onHandlePrivacy = useCallback((event) => {
		const value = event.currentTarget.checked
		formik.setFieldValue("privacy", !value);
	}, [formik]);

	//prettier-ignore
	const PrivacyLabel = useCallback(() => {
		return (
			<Fragment>
				I consent to the<a className="enquiry__tnc-pdf" href="https://www.easycover.my/privacy-policy-2/" target="_blank" rel="noreferrer"> Data Protection and Privacy Policy</a>.<span className="enquiry__tnc-required">*</span>
			</Fragment>
		)
	}, []);

	//prettier-ignore
	const TncLabel = useCallback(() => {
		return (
			<Fragment>
				I have read <a className="enquiry__tnc-pdf" href="https://www.easycover.my/terms/" target="_blank" rel="noreferrer">Terms and Conditions</a> herein and fully understand and agree with the said Terms and Conditions.<span className="enquiry__tnc-required">*</span>
			</Fragment>
		)
	}, []);

	const IdentificationField = useCallback((obj) => {
		if (!obj.isPassport && obj.validIdentificationType) {
			/* prettier-ignore */
			return <AppMaskingInput required type="text" name="nric" label="NRIC No." placeholder="e.g. 901010-14-1234" disabled={obj.disabled} value={obj.values.nric} error={obj.errors.nric} touched={obj.touched.nric} onChange={obj.onChange} format={COMMON.MASKING.NRIC} />
		}

		if (obj.isPassport && obj.validIdentificationType) {
			/* prettier-ignore */
			return <AppInput required type="text" name="passport" label="Passport" placeholder="e.g. VD1289281" disabled={obj.disabled} value={obj.values.passport} error={obj.errors.passport} touched={obj.touched.passport} onFormat={formatPassportString} onChange={obj.onChange} />
		}

		return null;
	}, []);

	return (
		<div className="page-enquiry">
			<div className="enquiry">
				<form className="enquiry__form" onSubmit={formik.handleSubmit}>
					<div className="enquiry__container">
						<div className="enquiry__box">
							{!messageType && !formik.isSubmitting && (
								<div className="enquiry__wrapper">
									<h1 className="enquiry__title">Motor Insurance</h1>
									<p className="enquiry__description">Get quote of your motor insurance with few simple steps!</p>

									<div className="enquiry__box-body">
										{/* prettier-ignore */}
										<AppSelectInput required type="text" name="identificationType" label="ID Type" placeholder="Please Select" loadOptions={getIdentificationListing} value={formik.values.identificationType} error={formik.errors.identificationType} touched={formik.touched.identificationType} onChange={formik.handleChange} disabled={formik.isSubmitting} searchable={false} />

										{/* prettier-ignore */}
										<IdentificationField values={formik.values} errors={formik.errors} touched={formik.touched} onChange={formik.handleChange} disabled={formik.isSubmitting} isPassport={isPassport} validIdentificationType={validIdentificationType} />

										{/* prettier-ignore */}
										<AppInput required type="text" maxLength={20} name="vehicleRegNo" label="Vehicle Registration No." placeholder="e.g. WXY1234" value={formik.values.vehicleRegNo} touched={formik.touched.vehicleRegNo} error={formik.errors.vehicleRegNo} onChange={formik.handleChange} onFormat={formatVehicleString} disabled={formik.isSubmitting} />

										{/* prettier-ignore */}
										<AppInput required type="number" maxLength={5} name="postcode" label="Postcode" placeholder="e.g. 57000" value={formik.values.postcode} touched={formik.touched.postcode} error={formik.errors.postcode} onChange={formik.handleChange} disabled={formik.isSubmitting} />

										{/* prettier-ignore */}
										<AppCheckbox required name="tnc" onClick={onHandleTnc} label={<TncLabel />} error={formik.errors.tnc} value={formik.values.tnc} touched={formik.touched.tnc} disabled={formik.isSubmitting} />

										<AppCheckbox required name="tnc" onClick={onHandlePrivacy} label={<PrivacyLabel />} error={formik.errors.privacy} value={formik.values.privacy} touched={formik.touched.privacy} disabled={formik.isSubmitting} />
									</div>

									<div className="enquiry__button-container">
										<AppButton type="submit" label="Get Quotation" disabled={formik.isSubmitting} />
									</div>
								</div>
							)}

							<AppLoading isLoading={formik.isSubmitting} text="Processing page during quotation selection page - We're currently fetching your quotation." />

							<AppCardStatus messageType={messageType} error={errorCode} setMessageType={setMessageType} />
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default PageEnquiry;
