import React, { memo, useState, useCallback } from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

import arrowUpIcon from "assets/images/pages/page-summary/arrow-up-icon.svg";

const AppCardAccordion = (props) => {
	const [isOpen, setIsOpen] = useState(false);

	const onHandleOpen = useCallback(() => {
		setIsOpen((prev) => !prev);
	}, []);

	return (
		<div className="app-card-accordion">
			<Accordion className="card-accordion" expanded={isOpen}>
				<AccordionSummary onClick={onHandleOpen} expandIcon={<Box component="img" src={arrowUpIcon} />} aria-controls="panel1a-content" id="panel1a-header">
					<p className="card-accordion__title">{props.data?.title}</p>
				</AccordionSummary>

				<AccordionDetails>
					{props.data?.info?.map((o) => (
						<ul className="accordion-list" key={o.label}>
							<li className="accordion-list__item">
								<p className="accordion-list__text">{o.label}</p>
								<p className="accordion-list__text accordion-list__text--value">{o.value}</p>
							</li>
						</ul>
					))}
				</AccordionDetails>
			</Accordion>
		</div>
	);
};

AppCardAccordion.propTypes = {
	data: PropTypes.shape({
		title: PropTypes.string.isRequired,
		info: PropTypes.arrayOf(
			PropTypes.shape({
				label: PropTypes.string.isRequired,
				value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
			})
		),
	}),
};

export default memo(AppCardAccordion);
