import api from "services/api";
import serveRequestErrors from "common/serve-request-errors";

const getStateListing = async () => {
	let response = null;
	let errorMessage = null;

	try {
		const params = { size: 25, page: 0, sort: "label,asc" };
		const res = await api.get.general.states(params);
		response = { content: res.content };
	} catch (error) {
		errorMessage = serveRequestErrors(error);
	}

	if (response) return response;

	throw errorMessage;
};

export default getStateListing;
