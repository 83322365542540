import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import pathnames from "routes/pathnames";
import AppButton from "components/app-button";
import { resetMotor } from "store/slices/motor";
import logo from "assets/images/logo.webp";

const PageHome = () => {
	const timer = useRef();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const referralCode = urlParams.get("myReferralCode");

	const onHandleNavigateMotor = () => {
		if (referralCode) {
			navigate(pathnames.enquiry + `?myReferralCode=${referralCode}`);
		} else {
			navigate(pathnames.enquiry);
		}
	};

	useEffect(() => {
		timer.current = setTimeout(() => {
			dispatch(resetMotor());
		}, 100);

		return () => {
			clearTimeout(timer.current);
		};
	}, [dispatch]);

	return (
		<div className="page-home">
			<div className="home">
				<main className="main">
					<div className="main__content main__content--background">
						<div className="main__logo">
							<img src={logo} alt="logo" />
						</div>
					</div>

					<div className="main__content main__content--form">
						<div className="main__form">
							<h1 className="main__title">Insure and secure your Motor with easycover.my</h1>
							<p className="main__description">Ready to renew your car insurance? </p>
							<p className="main__description">Have it delivered right at your doorstep with easycover.my.</p>
							<p className="main__description">Get FREE quotation now!</p>

							<div className="main__button-container">
								<AppButton type="button" label="Let's get started" onClick={onHandleNavigateMotor} />
							</div>
						</div>
					</div>
				</main>
			</div>
		</div>
	);
};

export default PageHome;
