import React, { memo, useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";

import processingIcon from "assets/images/pages/page-enquiry/processing-icon.webp";

const AppLoading = (props) => {
	const timerCounter = useRef();
	const [loading, setLoading] = useState("");

	useEffect(() => {
		if (props.isLoading) {
			timerCounter.current = setInterval(() => {
				setLoading((prev) => {
					if (prev.length === 3) return "";
					return (prev += "•");
				});
			}, 1000);

			return () => {
				setLoading("");
				clearInterval(timerCounter.current);
			};
		}
	}, [props.isLoading]);

	if (!props.isLoading) return null;

	return (
		<div className="app-loading">
			<div className="loading">
				<div className="loading__body">
					<img className="loading__processing-icon" src={processingIcon} alt="processing" />

					<p className="loading__title">{`Processing${loading}`}</p>
					<p className="loading__sub-title">{props.text}</p>
				</div>
			</div>
		</div>
	);
};

AppLoading.propTypes = {
	isLoading: PropTypes.bool,
	text: PropTypes.string,
};

export default memo(AppLoading);
