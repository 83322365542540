import { createContext, memo, useEffect, useRef, useCallback, useState } from "react";

import COMMON from "common";
import services from "services";
import sanitizeError from "common/sanitize-error";
import initialiseAmitabha from "common/initialise-amitabha";
import withAppIntegration from "contexts/with-app-integration";

const sharedStyles = "margin: 5px 0; padding: 8px; border-radius: 20px; color: #FFFFFF;";
const initStyles = `${sharedStyles} background: #673AB7;`;
const ejectStyles = `${sharedStyles} background: #ffb127;`;
const reqDataStyles = `${sharedStyles} background: #F8EA8C;`;
const reqParamsStyles = `${sharedStyles} background: #ff9514;`;
const respSuccessStyles = `${sharedStyles} background: #32ac97;`;
const errorStyles = `${sharedStyles} background: #F79489;`;
const debug = process.env.NODE_ENV === "development";

const interceptorDebug = (title, styles, data = null) => {
	if (!debug) return;

	console.log(`%c ${title}`, styles);

	if (data) console.log(data);

	console.log("");
};

export const AxiosContext = createContext();

const InterceptorProvider = ({ children, routes }) => {
	const controller = useRef({});
	const [initialed, setInitialed] = useState(false);
	const requestInterceptor = useRef(null);
	const responseInterceptor = useRef(null);

	const onHandleRequest = useCallback((config) => {
		switch (config.method) {
			case "get":
				interceptorDebug(`GET REQUESTING 🚀 ${config.url}`, reqParamsStyles, config.params);
				break;
			default:
				interceptorDebug(`POST REQUESTING 🚀 ${config.url}`, reqDataStyles, config.data);
				break;
		}

		let token = null;
		let signal = {};

		if (config.cancelId) {
			controller.current[config.cancelId] = new AbortController();
			signal = { signal: controller.current[config.cancelId].signal };
		}

		const localToken = localStorage.getItem(COMMON.AUTH_TOKEN);
		const sessionToken = sessionStorage.getItem(COMMON.AUTH_TOKEN);

		if (localToken) {
			token = localToken;
		} else {
			token = sessionToken;
		}

		if (token && config.token !== false) config.headers.Authorization = "Bearer " + token;

		return { ...config, ...signal };
	}, []);

	const onHandleRequestError = useCallback((error) => {
		interceptorDebug("REQUESTING ERROR 👎", errorStyles, sanitizeError(error));
		return Promise.reject(error);
	}, []);

	const onHandleResponse = useCallback((response) => {
		interceptorDebug(`RESPONSE SUCCESS: 🌟 ${response.config.url}`, respSuccessStyles, response.data);
		return response.data;
	}, []);

	//prettier-ignore
	const onResponseError = useCallback(async (error) => {
		const message = sanitizeError(error) || error?.message + " uri: " + error.config.url;
		interceptorDebug("RESPONSE ERROR 🥲", errorStyles, message);
		return Promise.reject(error);
	}, []);

	const onHandleCancelRequest = useCallback((id) => {
		if (controller.current[id]) controller.current[id]?.abort();
	}, []);

	const onHandleEjectAxiosInterceptor = useCallback(() => {
		if (requestInterceptor.current !== null) {
			interceptorDebug("Ejected Request Axios Interceptor! 👋", ejectStyles);
			services.interceptors.request.eject(requestInterceptor.current);
		}
		if (responseInterceptor.current !== null) {
			interceptorDebug("Ejected Response Axios Interceptor! 👋", ejectStyles);
			services.interceptors.response.eject(responseInterceptor.current);
		}
	}, []);

	useEffect(() => {
		initialiseAmitabha();
		interceptorDebug(`Init Axios Interceptor! 🎉`, initStyles);
		requestInterceptor.current = services.interceptors.request.use(onHandleRequest, onHandleRequestError, { synchronous: true });
		responseInterceptor.current = services.interceptors.response.use(onHandleResponse, onResponseError, { synchronous: true });
		setInitialed(true);
	}, [onHandleRequest, onHandleRequestError, onHandleResponse, onResponseError]);

	useEffect(() => {
		return () => {
			onHandleEjectAxiosInterceptor();
		};
	}, [onHandleEjectAxiosInterceptor]);

	if (!initialed) return null;

	return <AxiosContext.Provider value={{ onHandleCancelRequest }}>{children}</AxiosContext.Provider>;
};

export default memo(withAppIntegration(InterceptorProvider));
