export const getDateOfBirthAge = (date) => {
	const today = new Date();
	const birthDate = new Date(date);
	const month = today.getMonth() - birthDate.getMonth();
	const sameMonth = month === 0;
	const isTodayBeforeBirthDate = today.getDate() < birthDate.getDate();
	let age = today.getFullYear() - birthDate.getFullYear();

	if (month < 0 || (sameMonth && isTodayBeforeBirthDate)) age -= 1;

	return age;
};