import React, { memo } from "react";
import { Link } from "react-router-dom";

import pathnames from "routes/pathnames";
import logo from "assets/images/logo-white.webp";
import circle from "assets/images/header-circle.webp";

const AppHeader = () => {

	return (
		<header className="app-header">
			<div className="header">
				<Link to={pathnames.home}>
					<img className="header__image" src={logo} alt="logo" />
				</Link>
			</div>
			<img className="header__circle" src={circle} alt="circle" />
		</header>
	);
};

export default memo(AppHeader);
